<template>
          <v-list color="white rounded" v-show="device == 'Desktop'">
            <v-list-group
            class="mt-n1"
              no-action
              v-show="device == 'Desktop'"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-show="device == 'Desktop'"><span style="color:black;" class="text-button">Informasi Icon</span></v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item style="margin-left:-70px" v-show="device == 'Desktop'">
                <v-list-item-content class="mt-n6" v-show="device == 'Desktop'">
                    <v-carousel
                    cycle
                    height="160px"
                    :show-arrows="false"
                    hide-delimiter-background
                >  
                <v-carousel-item v-show="device == 'Desktop'">
                    <v-card
                      height="100%"
                    >
                      <v-card-text v-show="device == 'Desktop'">
                        <h4 style="color: black;">Status Aset</h4>
                        <p></p>
                        <v-icon class="mr-1 number">mdi-numeric-1-circle</v-icon><v-icon color="#a10115">mdi-car</v-icon><span class="syarat"> : Icon menunjukkan kendaraan <b class="tebal2">READY</b></span>
                        <h4></h4>
                        <v-divider/>
                        <v-icon class="mr-1 number">mdi-numeric-2-circle</v-icon><v-icon color="grey">mdi-car</v-icon><span class="syarat"> : Icon menunjukkan kendaraan <b class="tebal2">NOT READY</b></span>  
                        <h4></h4>
                        <v-divider/>
                      </v-card-text>
                    </v-card>
                  </v-carousel-item>
                  <v-carousel-item>
                    <v-card
                      height="100%"
                    >
                      <v-card-text v-show="device == 'Desktop'">
                      <b>Kondisi Aset</b>
                        <p></p>
                        <v-icon  class="mr-1 number">mdi-numeric-1-circle</v-icon><v-icon class="icon">mdi-car-traction-control</v-icon><span class="syarat"> : Icon kendaraan beroperasi <b class="tebal2">TES DRIVE</b></span>
                        <h4></h4>
                        <v-divider/>
                        <v-icon  class="mr-1 number">mdi-numeric-2-circle</v-icon><v-icon class="icon">mdi-car-clock</v-icon><span class="syarat"> : Icon kendaraan beroperasi<b class="tebal2"> DAILY</b></span>
                        <h4></h4>
                        <v-divider/>
                        <v-icon  class="mr-1 number">mdi-numeric-3-circle</v-icon><v-icon class="icon">mdi-car-key</v-icon><span class="syarat">: Icon kendaraan operasional <b class="tebal2">DIPINJAM</b></span>
                        <v-divider/>
                      </v-card-text>
                    </v-card>
                  </v-carousel-item>
                  </v-carousel>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
</template>
<script>
export default {
  data: () => ({
    device:null,
  }),
    methods:{
    list(){
        if(document.querySelector(".v-carousel__controls") != null){
            document.querySelector(".v-carousel__controls").style.display = 'none'
        }
      },
    },
    mounted(){
      this.device = this.$device
    },
}
</script>
<style>
.syarat{
  font-size: 12px;
  color: black !important;
}
.number{
  color: #41444B !important;
  font-size: 19px !important;
}
.tebal{
  color: #a10115 !important;
}
.tebal2{
  color: green !important;
}
.panah{
  color: black !important;
}
.wa{
  color: green !important;
}
.icon{
  font-size: 21px !important;
}
.baca{
 margin-left: 220px;
}
</style>
