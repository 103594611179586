<template>
  <div
      class="mx-auto"
      :style="{backgroundColor:'#CDDEFF',height:height}"
    > 
       <v-sheet
        height="135"
        class="mb-n15"
        color="white">
          <v-container>
            
            <v-row>
              <v-col cols="15" class="text text-center mt-1"><h4>HONDA AMARTHA INVENTORY</h4>
              <h5 class="text">HR & GA Department</h5>
              <h6 class="text">PT. Prospect Motor</h6></v-col>
            </v-row>
  
  
            <!-- <v-img class="mx-auto" max-width="120" src="@/assets/logo-honda.png" style="cursor: pointer;"></v-img> -->
          </v-container>
        </v-sheet>
  
              <!-- sementara hilang time di mobile -->
      <v-container>
        <v-row dense class="mt-1">
          <v-col cols="6">
            <v-alert
            @click="ChangeURL({to:'FormTesDrive'})"
              dense
              class="form1"
              color="#FAFF00"
              border="bottom"
              colored-border
              >
              <v-card-title color="black">
                <span id="teks-merah" class="mx-n4">
                  <v-icon color="white">mdi-file-document-plus-outline</v-icon>
                  Tes Drive
                </span>
              </v-card-title>
               <!-- <span id="teks-merah" class="text-overline text-center">Buat Form</span> -->
            </v-alert>
          </v-col>
  
          <v-col cols="6">
            <v-alert
              dense
              class="form2"
              color="#FAFF00"
              border="bottom"
              colored-border
              @click="ChangeURL({to:'FormPeminjaman'})"
             >
              <v-card-title>
                <span id="teks-merah" class="mx-n4">
                  <v-icon color="white">mdi-file-document-plus</v-icon>
                  Peminjaman
                </span>
              </v-card-title>
               <!-- <span id="teks-merah" class="text-overline text-center">Buat Form</span> -->
            </v-alert>
          </v-col>
  <!-- icon icon dashboard -->
          <v-col
            v-for="(item, i) in items"
            :key="i"
            cols="3"
          >
          <div class="text-center" v-show="item.locked == 'N'">
            <v-avatar
              rounded-xl
              size="60"
              color="white"
              @click="ChangeURL(item)"
            >
            <v-badge
              dot
              v-show="item.badge == true"
              color="green"
            >
              <v-icon :color="item.color"> 
                {{item.icon}}
              </v-icon>
              </v-badge>
              <v-icon :color="item.color" v-show="item.badge == false"> 
                {{item.icon}}
              </v-icon>
            </v-avatar>
  
            <p class="font mt-1">{{item.title}}</p>
            <p v-show="item.title == 'Pengembalian'" class="font mt-n5">{{item.data}}</p>
          </div>
  
          <div class="text-center" v-show="item.locked == 'Y'">
            <v-avatar
              rounded-xl
              size="60"
              color="white"
            >
              <v-icon color="gray"> 
                {{item.icon}}
              </v-icon>
            </v-avatar>
            <p class="font mt-1">{{item.title}}</p>
            <p v-show="item.title == 'Pengembalian'" class="font mt-n5">{{item.data}}</p>
          </div>
          </v-col>
  
          <v-col
            cols="3"
          >
          <div class="text-center" v-show="Laporan == true">
            <v-avatar
              rounded-xl
              color="#43658B"
              size="60"
              @click="ChangeURL({title:'Laporan',to:''})"
            >
              <v-icon color="#f6f6f6"> 
                mdi-chart-line
              </v-icon>
            </v-avatar>
  
            <p class="fontlap mt-1">Laporan</p>
          </div>
  
          <div class="text-center" v-show="Laporan == false">
            <v-avatar
              rounded-xl
              size="60"
              color="white"
            >
              <v-icon color="gray"> 
                mdi-chart-line
              </v-icon>
            </v-avatar>
            <p class="font mt-1">Laporan</p>
          </div>
          </v-col>

          <v-col
            cols="3"
          >
          <div class="text-center" v-show="WaDashboard == true">
            <v-avatar
              rounded-xl
              size="60"
              color="#43658B"
              @click="WaShow"
            >
              <v-icon color="#f6f6f6"> 
                mdi-whatsapp
              </v-icon>
            </v-avatar>
  
            <p class="fontlap mt-1">Whatsapp</p>
          </div>
          </v-col>

          <v-col
            cols="6"
          >
          <div class="text-center">
            <v-btn @click="OpenSyarat" class="syarat mb-3 mt-n2">Syarat Ketentuan<v-icon class="ml-1" small>mdi-arrow-right</v-icon></v-btn>
          </div>
          </v-col>
          <v-col
            cols="6"
          >
          <div class="text-center">
            <v-btn @click="OpenBulet" class="syarat2 ml-10 mt-n2">Bulletin<v-icon class="ml-1" small>mdi-arrow-right</v-icon></v-btn>
          </div>
          </v-col>
          
  
          <v-col cols="12" class="mb-2 mt-n2">
            <SyaratKetentuanMobile/>
          </v-col>
        </v-row>
        <Laporan />
        <FloatChat style="position:fixed;"/>

        <v-dialog :fullscreen="isFullscreen" v-model="SyaratState">
          <v-row dense>
            <v-col cols="12">
              <v-alert
                      dense
                      color="#FAFF00"
                      border="bottom"
                      colored-border>
                      <v-carousel
                        cycle
                        :show-arrows="false"
                        height="100%"
                        hide-delimiter-background
                      >
                        <v-carousel-item>
                          <v-container>
                          <v-sheet
                          height="100%">
                          <v-btn v-show="isSyarat == true" @click="CloseSyarat" icon><v-icon color="#43658B">mdi-close</v-icon></v-btn>
                          <v-btn v-show="isBulet == true" @click="CloseBulet" icon><v-icon color="#43658B">mdi-close</v-icon></v-btn>
                            <div v-show="isSyarat == true" class="pa-2" v-html="htmlSyarat"/>
                            <div v-show="isBulet == true" class="pa-2" v-html="htmlBulet"/>
                          </v-sheet>
                          </v-container>
                        </v-carousel-item>
                      </v-carousel>
                      </v-alert>
                    </v-col>
            </v-row>
        </v-dialog>

      </v-container>
  </div>
  </template>
<script>
  import Vue from 'vue'
  import SyaratKetentuanMobile from '@/components/SyaratKetentuanMobile/index.vue'
  import FloatChat from '@/components/FloatChat/index.vue'
  import Laporan from '@/components/DialogLaporan/index.vue'
  import API from "@/services/http";
    export default {
      components:{
        SyaratKetentuanMobile,FloatChat,Laporan,
      },
      data: () => ({
      dialog:false,
      logged:[],
      SyaratState:false,
      isSyarat:false,
      isBulet:false,
      htmlSyarat:'',
      htmlBulet:'',
      height:'600px',
      isFullscreen:false,
      value:true,
      Laporan:true,
      WaDashboard:false,
      realtime:null,
      time:null,
      active:true,
      UserPengguna:null,
      Department:null,
      Departmentdata:[],
      userlogged:localStorage.getItem('userlogged'),
        accitem: [
          {
              to:'/auth',
              text:'Logout'
          },
          {
              to:'/setting',
              text:'Pengaturan'
          }
        ],
        items: [],
      }),
      created(){
        let winheight = window.innerHeight
        this.height = winheight+'px'
        window.scrollTo(0,0)
        Vue.prototype.$setWaDashboard = this.setWaDashboard
        this.logged = JSON.parse(localStorage.getItem('logged'))
        this.items = this.$ItemDashboard
        this.getalldata()
        this.getTime()
        this.startTime()
        //this.getDepartment()
      },
      mounted(){
          let userlogged = localStorage.getItem('userlogged')
          let department=localStorage.getItem('departmentlogged')
          if(userlogged){
              this.dialog = false
              if(localStorage.getItem('bell') == undefined){
                this.$showDialogBell(true)
              }
              localStorage.setItem('bell','ok')
              this.Department = department
          }
      },
      methods:{
        getTime(){
          let hari = new Date().toISOString().substring(0,10)
          this.time = this.$DateConvert(hari)
        },
        getalldata(){
          API.get('totalalldata/'+this.logged.id_user).then(x=>{
            //user data
            let user = this.items.findIndex(x=>x.title == 'User')
            this.items[user].locked = this.logged.level > 2 && this.logged.level < 5 ? 'N' : 'Y'
            this.items[user].count = x.data.User
            this.items[user].isLoad = false
            //aset data
            let aset = this.items.findIndex(x=>x.title == 'Aset')
            this.items[aset].count = x.data.Aset.count
            this.items[aset].isLoad = false
            this.items[aset].badge = x.data.seen == 'Y' ? true : false
            //tesdrive data
            let tesdrive = this.items.findIndex(x=>x.title == 'Tes Drive')
            this.items[tesdrive].count = x.data.TesDrive.totalform
            this.items[tesdrive].isLoad = false
            this.items[tesdrive].badge = x.data.TesDrive.seen == 'Y' ? true : false
            //peminjaman data
            let peminjaman = this.items.findIndex(x=>x.title == 'Peminjaman')
            this.items[peminjaman].count = x.data.Peminjaman.totalform
            this.items[peminjaman].isLoad = false
            this.items[peminjaman].badge = x.data.Peminjaman.seen == 'Y' ? true : false
            //pengembalian tesdrive
            let p_tesdrive = this.items.findIndex(x=>x.data == 'Tes Drive')
            // this.items[p_tesdrive].locked = u.level > 1 ? 'N' : 'Y'
            this.items[p_tesdrive].count = x.data.PengembalianTesDrive.totalform
            this.items[p_tesdrive].isLoad = false
            this.items[p_tesdrive].badge = x.data.PengembalianTesDrive.seen == 'Y' ? true : false
            // p_peminjaman
            let p_peminjaman = this.items.findIndex(x=>x.data == 'Peminjaman')
            // this.items[p_peminjaman].locked = u.level > 1 ? 'N' : 'Y'
            this.items[p_peminjaman].count = x.data.PengembalianPeminjaman.totalform
            this.items[p_peminjaman].isLoad = false
            this.items[p_peminjaman].badge = x.data.PengembalianPeminjaman.seen == 'Y' ? true : false
            this.Laporan = this.logged.level > 2 ? true : false
            this.Department = x.data.Department
            this.GetDataCustom()
            this.$loading(false)
          })
        },
        GetDataCustom(){
          API.get('custom').then(x=>{
            this.htmlSyarat = x.data.find(s => s.type == 'SK').html
            this.htmlBulet = x.data.find(s => s.type == 'Buletin').html
          })
        },
        getDataUser(){
          let u = this.logged
          console.log(u)
          API.get("totaluser").then(x=>{
            let index = this.items.findIndex(x=>x.title == 'User')
            this.items[index].locked = u.level == 3 ? 'N' : 'Y'
            this.items[index].count = x.data
          })
        },
        getDataAset(){
          API.get("totalaset/"+this.logged.id_user).then(x=>{
            let index = this.items.findIndex(x=>x.title == 'Aset')
            this.items[index].count = x.data
          })
        },
        getDataTesDrive(){
          API.get("totaltesdrive/"+this.logged.id_user).then(x=>{
            let index = this.items.findIndex(x=>x.title == 'Tes Drive')
            this.items[index].count = x.data.totalform
          })
        },
        getDataPeminjaman(){
          API.get("totalpeminjaman/"+this.logged.id_user).then(x=>{
            let index = this.items.findIndex(x=>x.title == 'Peminjaman')
            this.items[index].count = x.data.totalform
          })
        },
        getDataPengembalianTesDrive(){
          let u = this.logged
          API.get("totalpengembaliantesdrive/"+this.logged.id_user).then(x=>{
            let index = this.items.findIndex(x=>x.data == 'Tes Drive')
            this.items[index].locked = u.level > 1 ? 'N' : 'Y'
            this.items[index].count = x.data.totalform
          })
        },
        getDataPengembalianPeminjaman(){
          let u = this.logged
          API.get("totalpengembalianpeminjaman/"+this.logged.id_user).then(x=>{
            let index = this.items.findIndex(x=>x.data == 'Peminjaman')
            this.items[index].locked = u.level > 1 ? 'N' : 'Y'
            this.items[index].count = x.data.totalform
            this.$loading(false)
          })
        },
        getDepartment(){
          API.get("/department").then(x=>{
            this.Departmentdata=x.data
          })
        },
        OpenBulet(){
          this.isFullscreen = false
          this.SyaratState = !this.SyaratState
          this.isBulet = !this.isBulet
        },
        OpenSyarat(){
          this.isFullscreen = true
          this.SyaratState = !this.SyaratState
          this.isSyarat = !this.isSyarat
        },
        CloseSyarat(){
          this.SyaratState = !this.SyaratState
          this.isSyarat = !this.isSyarat
        },
        CloseBulet(){
          this.SyaratState = !this.SyaratState
          this.isBulet = !this.isBulet
        },
        setWaDashboard(){
          this.WaDashboard = !this.WaDashboard
        },
        ShowBell(x){
            this.$showDialogBell(x)
        },
        LogOut(){
          localStorage.removeItem('userlogged')
          localStorage.removeItem('logged')
          localStorage.removeItem('departmentlogged')
          this.$router.go('/auth')
        },
        WaShow(){
          this.WaDashboard = false
          this.$setWaTrue()
        },
        ChangeURL(x){
          if(x.title == 'Laporan'){
            this.$AllLaporan()
          }
          this.$ChangeURL(x.to)
        },
        
          SavePengguna(){
            if(this.UserPengguna != null && this.Department != null){
              let Department = this.Departmentdata.find(x=>x.id_department == this.Department)
              localStorage.setItem('userlogged',this.UserPengguna)
              localStorage.setItem('departmentlogged',JSON.stringify(Department))
              this.userlogged = this.UserPengguna
              this.$logged()
              this.dialog = false
            }
          },
          startTime() {
            const today = new Date();
            let h = today.getHours();
            let m = today.getMinutes();
            let s = today.getSeconds();
            m = this.checkTime(m);
            s = this.checkTime(s);
            this.realtime =  h + ":" + m + ":" + s;
            setTimeout(this.startTime, 1000);
          },
  
          checkTime(i){
            if (i < 10){
              i = "0" + i
              }
            return i
          }
      }
    }
  </script>
  <style scoped>
  #teks-merah{
    font-size: 14px;
    color:  #FFFFFF;
  }
  .font{
   font-family: 'Calibri';
   font-size: 11px;
   font-weight: bold;
  }
  .fontlap{
   font-family: 'Calibri';
   font-size: 11px;
   font-weight: bold;
   color: #43658B !important;
  }
  .v-carousel__controls{
    display:none !important;
  }
  .v-list .v-list-item--active{
    color:#a10115;
  }
  .fab{
    bottom:80px;
    right:20px;
    position: fixed;
    -webkit-transition: all 2s ease-in-out;
    transition: all 2s ease-in-out;
    z-index: 4;
  }
  .form1{
    background-color: #43658B !important;
  }
  .form2{
    background-color: #43658B !important;
  }
  .text{
    color:#a10115 !important;
  }
  .syarat2{
    background-color: white !important; 
    color: #a10115 !important;
    font-size: 13px !important;
  }
  .syarat{
    background-color: #a10115 !important;
    color: white !important;
    font-size: 13px !important;
  }
  </style>