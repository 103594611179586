<template>
  <v-layout class="fab" :style="{left:leftpos}" v-show="fabhide == false">
    <!-- <v-fab-transition>
    <v-btn
      key="mdi-whatsapp"
      color="#a10115"
      fab
      large
      dark
      bottom
      left
      class="v-btn--example"
    >
      <v-icon>mdi-whatsapp</v-icon>
    </v-btn>
    </v-fab-transition> -->
      <v-speed-dial
      v-model="fab"
      id="fab"
      bottom
      left
      :direction="'top'"
      :open-on-hover="false"
      :transition="'slide-y-reverse-transition'"
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="#a10115"
          dark
          fab
        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-chat
          </v-icon>
        </v-btn>
      </template>
      <v-btn
        dark
        small
        data-nama="Agus" data-wa="6287819003468"
        @click="ToWa('6287819003468')"
        color="green"
      >
      <span data-nama="Agus" data-wa="6287819003468" class="Agus mr-2">Agus</span>
        <v-icon data-nama="Agus" data-wa="6287819003468">mdi-whatsapp</v-icon>
      </v-btn>
      <v-btn
        dark
        small
        data-nama="Jordy" data-wa="6285250384489"
        @click="ToWa('6285250384489')"
        color="green"
      >
      <span data-nama="Jordy" data-wa="6285250384489" class="Jordy mr-2">Jordy </span>
        <v-icon data-nama="Jordy" data-wa="6285250384489">mdi-whatsapp</v-icon>
      </v-btn>
    </v-speed-dial>
    <v-btn fab class="fab-close" :style="{left:closeleft}" icon @click="closewa" color="#a10115"><v-icon>mdi-close</v-icon></v-btn>
    </v-layout>
</template>
<script>
  import Vue from 'vue'
export default {
  data: () => ({
      fab:false,
      fabhide:false,
      device:null,
      closeleft:window.innerWidth+'px',
      leftpos:window.innerWidth+'px',
  }),
  mounted(){
    Vue.prototype.$setWaTrue = this.SetWaTrue
    this.device = this.$device
    this.leftpos = window.innerWidth-110+'px' 
    this.closeleft = window.innerWidth-50+'px'
  },
  methods:{
      SetWaTrue(){
        this.fabhide = false
      },
      ChatBtn(){
        this.fab = true
      },
      closewa(){
        this.fabhide = true
        this.$setWaDashboard()
      },
      ToWa(x){
          window.open('https://wa.me/'+x+'?text=Saya User HAI Mau Bertanya', '_blank').focus()
      },
      show(e){
        console.log(e)
        // if(this.device == 'Mobile'){
        //   this.fab = !this.fab
        //   // console.log(e.touches[0].target.dataset)
        //   if(e.touches[0].target.dataset.wa){
        //     this.ToWa(e.touches[0].target.dataset.wa)
        //   }
        // }
        console.log('clicked')
      }
  }

}
</script>
<style>
.fab{
/* bottom:30px !important;  */
bottom:10% !important;
position: fixed;
-webkit-transition: all 1s ease-in-out;
transition: all 1s ease-in-out;
z-index: 4;
}
.fab-close{
bottom:12% !important; 
left: 100px;
position: fixed;
-webkit-transition: all 1s ease-in-out;
transition: all 1s ease-in-out;
z-index: 7;
}
</style>